import { Category } from "../../types/Categorys";
import { Coldex, ColdexUser } from "../../types/Coldex";
import { ColdexConfig } from "../../types/ColdexConfig";
import { ColdexInfo } from "../../types/ColdexInfo";
import { Company, SimpleCompany } from "../../types/Company";
import { CompanyColdexCategory } from "../../types/CompanyColdexCategory";
import { Padrino } from "../../types/Padrino";
import { Parameter } from "../../types/Parameter";
import { PermissionxRol } from "../../types/Permission";
import { UserResponseSend } from "../../types/ReourcesFormUser";
import { Role } from "../../types/Role";
import { RowDataColdex } from "../../types/RowDataColdex";
import { Section, SectionForm, SectionResource } from "../../types/Section";
import { SectorxCompany } from "../../types/SectorxCompany";
import { Security } from "../../types/Security";
import { User } from "../../types/User";
import { UserAnswerxColdex } from "../../types/UserAnswerxColdex";
import { UserColdex } from "../../types/UserColdex";
import { TemplatexColdex } from "../../types/TemplatexColdex";
import { ProfilexColdex } from "../../types/ProfilexColdex";
import { PartnerEvaluated } from "../../types/PartnerEvaluated";
import { PayloadAction } from "@reduxjs/toolkit";
import { ResponseType } from "../../types/ResponseType";
import { LastPartnerEvaluated } from "../../types/LastPartnerEvaluated";
import { ProfilePollType } from "../../types/ProfilePollType";

export interface State {
  action?: PayloadAction;
}

export type SignalState = "loading" | "success" | "error";

export interface SignalController<E> {
  event?: SignalState;
  value?: E;
  error?: string;
  userError?: string;
}

export class Signal<E> {
  event?: SignalState;
  value?: E;
  error?: string;
  userError?: string;

  constructor(s?: SignalController<E>) {
    if (s?.event) this.event = s.event;
    if (s?.value) this.value = s.value;
    if (s?.error) this.error = s.error;
    if (s?.userError) this.userError = s.userError;
  }

  static default<D>(value?: D): Signal<D> {
    return new Signal<D>({
      value,
    });
  }

  static loading<D>(value?: D): Signal<D> {
    return new Signal<D>({
      event: "loading",
      value,
    });
  }

  static success<D>(value: D): Signal<D> {
    return new Signal<D>({
      event: "success",
      value,
    });
  }

  static error<D>(error: string, userError?: string): Signal<D> {
    return new Signal<D>({
      event: "error",
      error,
      userError,
    });
  }
}

export interface IStore {
  shortMessage: string;
  resultMessage: string;
  dataError: boolean;
  open: boolean;
  isLoading: boolean;
}

export interface IAdminUsersState extends IStore {
  users: Array<User>;
  user: User | undefined;
}
export interface ICategoryxColdexState extends IStore {
  categoryResults: Array<Category>;
}
export interface IColdexState extends IStore {
  coldexs: Array<Coldex>;
}
export interface CompanyState extends IStore {
  companiesList: Array<Company>;
}
export interface ICompanyXIndColdexState extends IStore {
  CIndXColdex: Array<SectorxCompany>;
}
export interface ICompanyColdexCategory extends IStore {
  companyColdexCategory: CompanyColdexCategory | undefined;
}

export interface IFormUserResponseState extends IStore {
  UserResponsesSend: Array<UserResponseSend>;
  responseSendResult: UserResponseSend | undefined;
}
export interface ILoginState extends IStore {
  isLogged: boolean;
  token: string;
  expiresIn: number;
  user: User;
}
export interface IPadrinoxColdexState extends IStore {
  padrinos: Array<Padrino>;
}
export interface IParametersState extends IStore {
  parameters: Parameters;
}
export interface Parameters {
  parametersFormUser: Array<Parameter>;
  parametersTypeRole: Array<Parameter>;
  parameterCompanyType: Array<Parameter>;
  parameterCompanyTDOC: Array<Parameter>;
  parametersColdexType: Array<Parameter>;
  parameterSectionType: Array<Parameter>;
  parameterResourcesForm: Array<Parameter>;
  parameterResourcesType: Array<Parameter>;
  parameterInconsType: Array<Parameter>;
}
export interface IPermissionXRolState extends IStore {
  permissionRolUser: Array<PermissionxRol>;
  permissionRol: Array<PermissionxRol>;
  isLoadingUpdate: boolean;
  permissionUpdate: number;
}
export interface IPollTypeState extends IStore {
  polls: Array<IPolls>;
}
export interface IPolls {
  pollCodeId: string;
  description: string;
  type: string;
  status: boolean;
}
export interface IPollxColdexConfigState extends IStore {
  info: ColdexInfo | null;
  pollxColdex: Array<ColdexConfig>;
}
export interface IRolState extends IStore {
  rolsList: Array<Role>;
}
export interface IRowDataColdexState extends IStore {
  rowDateColdex: Array<RowDataColdex>;
}
export interface ISectionState extends IStore {
  sections: Array<Section>;
}
export interface ISectionFormState extends IStore {
  sectionsforms: Array<SectionForm>;
}
export interface ISectionResourceState extends IStore {
  sectionsresources: Array<SectionResource>;
}
export interface ISecurityxUserxColdexState extends IStore {
  security: Array<Security>;
}
export interface IUserAnswerxColdex extends IStore {
  answers: Array<UserAnswerxColdex>;
}
export interface IUserxColdexState extends IStore {
  coldex: Array<ColdexUser>;
  users: Array<UserColdex>;
}

export interface ITempletexColdexState extends IStore {
  templateResults?: TemplatexColdex | null;
}

export interface IProfileViewState extends IStore {
  userProfiles?: ProfilexColdex[];
  profiles: ProfilexColdex[];
  selected: ProfilexColdex[];
  saved?: ProfilexColdex[];
  idPolls: string[][];
  exported: boolean;
  securitySaved: boolean;
}

export interface IPartnerToEvaluateViewState extends IStore {
  userPartners?: any[];
  companyTypes: string[];
  selectedCompanyType?: string;
  lastEvaluatedCompanies: LastPartnerEvaluated[];
  categories: Category[];
  selectedCategories: Category[];
  partners: PartnerEvaluated[];
  selectedPartners: PartnerEvaluated[];
  savedPartners?: PartnerEvaluated[];
  isOpen: boolean;
  executed: boolean;
  securitySaved: boolean;
  done: boolean;
}

export interface IFocusStep {
  total: number;
  step: number;
  dimension: number;
  thematic: number;
  question: number;
  company?: number;
}

export interface IViewOfQuestionnaireToAnswerState extends State {
  profiles: ProfilexColdex[];
  coldex: Signal<Coldex>;
  companyTypes: Signal<string[]>;
  structure: Signal<ColdexConfig[]>;
  advance: Signal<UserAnswerxColdex>;
  companies: Signal<SimpleCompany[]>;
  responseTypes: Signal<ResponseType[]>;
  answers: Signal<RowDataColdex[]>;
  permissions: Signal<ProfilePollType[]>;
  answersSelected: RowDataColdex[];
  dimension?: ColdexConfig;
  thematic?: ColdexConfig;
  question?: ColdexConfig;
  company?: SimpleCompany;
  focus: IFocusStep;
}

export interface ITempleteState {
  file: string;
  isLoading: boolean;
  iserror: boolean;
  error: string;
  shortMessage: string;
}
export interface sendMailxUserState {
  response: string;
  httpError: string | any;
  httpErrorUpdate: string;
  isSendMail: boolean;
  isSendMailEnd: boolean;
  isUpdateTemplateMail: boolean;
  responseUpdate: string;
  isError: boolean;
  open: boolean;
}
export interface ResourcesState {
  resourcesList: Array<any>;
  apiExpection: Array<any>;
  resultMessage: string;
  dataError: boolean;
  isLoading: boolean;
  shortMessage: string;
  open: boolean;
}

export interface IReports {
  session: {
    companyName: string | null;
    companyRol: string | null;
    userPermission: string | null;
    category: Array<string>;
    isSession: boolean;
  };
  visiblePlotIndex: number;
  visibleSectionIndex: number;
}
export interface IMonotoringReport {
  company_by_evaluator: Array<any> | null;
  evaluator_position: Array<any> | null;
  evaluator_company: Array<any> | null;
  is_loading: boolean;
  category: Array<string> | null;
  company_to: Array<string> | null;
  company_from: Array<string> | null;
}
